import { useState } from "react";
import { store } from "../../context/store";
//Models
import { Option } from "../../Models/Option";
//Api
import { Client, State } from "../../services/ApiClient";
import Config from "../../Configuration/config";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface StatusSelectState {
  options: Option[];
}

const StatusSelect = () => {
  const [statesFromClient, setStatesFromClient] = useState<State[]>([]);
  const [currentSelectStatusName, setCurrentSelectStatusName] = useState("");
  const [statusSelectState, setStatusSelectState] = useState<StatusSelectState>(
    {
      options: [],
    }
  );

  /**
   * Converts state objects to options objects
   * so they can be displayed in a selection.
   */
  const convertStatesToOptions = (states: State[]) => {
    const options: Option[] = [];
    states.forEach((state) => {
      if (state) {
        if (!state.name) {
          return;
        }
        options.push({
          value: String(state.index),
          label: state.name,
          color: state.hex ? state.hex : "",
        });
      }
    });
    return options;
  };

  const getStates = async () => {
    const client: Client = new Client(Config.ServerUrl as string);
    client
      .getStates()
      .then((states: State[]) => {
        setStatusSelectState({ options: convertStatesToOptions(states) });
        setStatesFromClient(states);
      })
      .catch((error: any) => {
        console.log(error.message);
      });
  };

  if (statusSelectState.options.length === 0) {
    getStates();
  }

  const statusSelectionChanged = (event: SelectChangeEvent) => {
    setCurrentSelectStatusName(event.target.value);
    const state: State | undefined = statesFromClient.find(
      (value) => value.name === event.target.value
    );

    if (state instanceof State) {
      try {
        store.dispatch({
          type: "SET_STATUS",
          payload: state,
        });
      } catch (error) {
        console.log(error);
      }
    } else {
    }
  };

  return (
    <div
      id="statusSelectPanel"
      style={{
        marginBottom: "15px",
        marginLeft: "10vw",
        marginRight: "10vw",
        paddingLeft: "0px",
        paddingRight: "0px",
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="status-select-label">Status</InputLabel>
        <Select
          labelId="status-select-label"
          id="status-select"
          value={currentSelectStatusName}
          label="Status"
          onChange={statusSelectionChanged}
        >
          {statusSelectState.options.map((value, index) => {
            return (
              <MenuItem
                key={`${value.value}${value.label}`}
                value={value.label}
              >
                <span style={{ color: value.color, marginRight: "15px" }}>
                  ⬤
                </span>
                {value.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};
export default StatusSelect;
