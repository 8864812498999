import {Alert, AlertColor, Box, CircularProgress, Modal, Snackbar} from "@mui/material";
import {useState} from "react";
import {Client} from "../services/ApiClient";
import Config from "../Configuration/config";

interface State {
	snackbarOpen: boolean;
	snackbarMessage: string;
	severity: AlertColor;
	showSpinner: boolean;
}

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
};

const Reset = () => {
	const [state, setState] = useState<State>({
		snackbarOpen: false,
		snackbarMessage: "Reset successful!",
		severity: "success",
		showSpinner: false,
	});

	const resetModel = async (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setState({snackbarOpen: state.snackbarOpen, snackbarMessage: state.snackbarMessage, severity: state.severity, showSpinner: true});
		const client: Client = new Client(Config.ServerUrl as string);
		try {
			await client.resetModel(Config.StreamId as string);
		} catch (error: any) {
			setState({snackbarOpen: true, snackbarMessage: "An error occured", severity: "error", showSpinner: false});
			return;
		}
		setState({snackbarOpen: true, snackbarMessage: state.snackbarMessage, severity: "info", showSpinner: false});
	};

	const handleClose = () => {
		setState({snackbarOpen: false, snackbarMessage: state.snackbarMessage, severity: state.severity, showSpinner: state.showSpinner});
	};

	return (
		<div className="row">
			<div className="col-xl-1 col-lg-1  col-md-1 col-xl-offset-9 col-lg-offset-9 col-md-offset-9"></div>
			<button id="resetButton" onClick={resetModel} type="button" className="btn btn-sm btn-outline-primary pull-right" style={{borderRadius: "10px"}}>
				RESET
			</button>
			<Modal open={state.showSpinner} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box sx={style}>
					<CircularProgress color="secondary" />
				</Box>
			</Modal>
			<Snackbar anchorOrigin={{vertical: "bottom", horizontal: "center"}} open={state.snackbarOpen} autoHideDuration={5000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={state.severity}>
					{state.snackbarMessage}
				</Alert>
			</Snackbar>
		</div>
	);
};
export default Reset;
