import React, { useState } from "react";
import { Option } from "../../Models/Option";
import { store } from "../../context/store";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface State {
  options: Option[];
  devices: MediaDeviceInfo[];
}

const SourceSelect = () => {
  const [currentSourceName, setCurrentSourceName] = useState("");
  const [state, setState] = useState<State>({
    options: [],
    devices: [],
  });

  /**
   * Gets all available devices for the selection.
   */
  const getDevices = () => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices: MediaDeviceInfo[]) => {
        devices = devices.filter((d) => d.kind === "videoinput");
        const options: Option[] = [];
        devices.forEach((device: MediaDeviceInfo) => {
          options.push({
            value: device.deviceId,
            label: device.label,
            color: "",
          });
        });
        setState({ options: options, devices: devices });
      });
  };

  /**
   * Callback function that gets called when User rejects a permission dialogue.
   * @param reason Permission that has been denied.
   */
  const onRejected = (reason: any) => {
    console.log(reason);
  };

  /**
   * Opens dialoge to ask for cameraPermissions on page load.
   */
  const getCameraPermissions = () => {
    if (state.options.length === 0) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(getDevices, onRejected);
    }
  };

  getCameraPermissions();

  const sourceSelectionChanged = (event: SelectChangeEvent) => {
    state.devices.forEach((device) => {
      if (device.deviceId === event.target.value) {
        store.dispatch({ type: "SET_DEVICE", payload: device });
        setCurrentSourceName(event.target.value);
      }
    });
  };

  return (
    <div
      id="sourceSelectPanel"
      style={{
        marginBottom: "15px",
        marginLeft: "10vw",
        marginRight: "10vw",
        paddingLeft: "0px",
        paddingRight: "0px",
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="source-select-label">Device</InputLabel>
        <Select
          labelId="source-select-label"
          id="source-select"
          value={currentSourceName}
          label="Source"
          onChange={sourceSelectionChanged}
        >
          {state.options.map((value, index) => {
            return (
              <MenuItem key={value.value} value={value.value}>
                {value.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};
export default SourceSelect;
