import SourceSelect from "../scanconfig/SourceSelect";
import StatusSelect from "../scanconfig/StatusSelect";
import ScanArea from "./ScanArea";

const ScanSetup = () => {
  return (
    <div style={{ marginTop: "10vh" }}>
      <StatusSelect />
      <SourceSelect />
      <ScanArea />
    </div>
  );
};
export default ScanSetup;
