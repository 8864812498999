//Api
import {Client, State} from "../../services/ApiClient";
//React
import {useEffect, useState} from "react";
//QR
//import QrReader from "react-qr-reader";
import { QrReader } from '@blackbox-vision/react-qr-reader';
//Context
import {useSelector} from "react-redux";
import {QRState} from "../../context/QRReducer";
//MUI
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {Alert, AlertColor, Button, Snackbar, Typography} from "@mui/material";
import QrCodeIcon from "@mui/icons-material/QrCode";

import Config from "../../Configuration/config"

interface ScanAreaState {
	startedScanning: boolean;
}

interface SnackbarState {
	showSnackbar: boolean;
	snackbarMessage: string;
	severity: AlertColor;
}

interface QRScannerAttributes {
	constraints: {facingMode: "user" | "environment" | undefined};
	scanDelay: number;
	onResult:(arg0: any, arg1: any) => void;
}

const ScanArea = () => {
	const client: Client = new Client(Config.ServerUrl as string);
	const [scanAreaState, setScanAreaState] = useState<ScanAreaState>({
		startedScanning: false,
	});
	const [snackbarState, setSnackbarState] = useState<SnackbarState>({showSnackbar: false, snackbarMessage: "Scan successful!", severity: 'success'});
	const state: any = useSelector<QRState>((state: QRState) => state.status);
	const device: any = useSelector<QRState>((state: QRState) => state.device);
	let scanned: boolean = false;
	const [scannedRevitIds, setScannedRevitIds] = useState<string>("")

	useEffect(()=>{
		if(scannedRevitIds == ""){
			return;
		}
		if(!scanAreaState.startedScanning){
			setScannedRevitIds("");
			return;
		}
		client.transferScanData(scannedRevitIds, state).then(() => {
			setSnackbarState({showSnackbar: true, snackbarMessage: "Scan successful!", severity: "success"});
		}).catch((error: any) => {
			if (error == "State can not be assign twice"){
				setSnackbarState({showSnackbar: true, snackbarMessage: "An Id can take a status only once.", severity: "error"});
			}else{
				setSnackbarState({showSnackbar: true, snackbarMessage: "Data could not be transfered!", severity: "error"});
			}
		}).finally(() => {
			setScannedRevitIds("");
			setScanAreaState({startedScanning: false});
		});
	},[scannedRevitIds])

	const handleScan = async (data: string | null) => {
		if (!data || scanned) {
			return;
		}
		scanned = true;
		let splitted: string[];
		if (data.includes("revitid")) {
			splitted = data.split("revitid\n");
		} else {
			splitted = data.split("assemblyid\n");
			splitted[1] += ";test1;test2;test3"
		}
		scanned = false

		setScannedRevitIds(splitted[1]);
	};

	const handleError = (error: any) => {
		console.error(error);
	};

	const handleClose = () => {
		setSnackbarState({showSnackbar: false, snackbarMessage: state.snackbarMessage, severity: "success"});
	};

	const getQRScanner = () => {
		let borderProps: string = "5px solid gray";
		let qrReaderProps: QRScannerAttributes = {
			constraints: {facingMode: "environment"},
			scanDelay: 300,
			onResult: (result: any, error: any) => {
				if (!!result) {
					handleScan(result.text)
				  }
			},
		};

		if (state) {
			if (scanAreaState.startedScanning) {
				borderProps = borderProps.replace(" gray", " #00FF00");
			}
			return (
				<div
					style={{
						width: "300px",
						height: "300px",
						border: borderProps,
						margin: "1vh auto",
						marginTop: "2vh",
					}}>
					<QrReader {...qrReaderProps}/>
					<Snackbar anchorOrigin={{vertical: "bottom", horizontal: "center"}} open={snackbarState.showSnackbar} autoHideDuration={5000} onClose={handleClose}>
						<Alert severity={snackbarState.severity}>{snackbarState.snackbarMessage}</Alert>
					</Snackbar>
				</div>
			);
		}
		return (
			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					"& > :not(style)": {
						m: 1,
						height: "50vh",
					},
				}}>
				<Paper elevation={3} sx={{paddingTop: "10%"}}>
					<Typography sx={{fontSize: 20}} variant="body1" gutterBottom>
						Please Select a Status.
					</Typography>
					<Typography sx={{fontSize: 20}} variant="body1" gutterBottom>
						Click "Start Scan" to start scanning and hold your QRcode into the camera.
					</Typography>
				</Paper>
			</Box>
		);
	};

	const qrComponent = getQRScanner();
	const scanButtonDisabled: boolean = state !== null ? false : true;

	return (
		<div>
			<div style={{margin: "0px", paddingRight: "0px"}}>{qrComponent}</div>
			<Button
				disabled={scanButtonDisabled}
				style={{width: "200px", height: "50px"}}
				onClick={() => {
					scanned = false;

					setScanAreaState({startedScanning: !scanAreaState.startedScanning});
				}}
				variant="contained"
				endIcon={<QrCodeIcon />}
				color={scanAreaState.startedScanning ? "success" : "primary"}>
				{scanAreaState.startedScanning ? "Scanning..." : "Start Scan"}
			</Button>
		</div>
	);
};
export default ScanArea;
