const Scan = () => {
	return (
		<div className="row">
			<div className="col-xl-9 col-lg-9 col-md-9">
				<a className="navbar-brand" href="index.html">
					Scan
				</a>
			</div>
		</div>
	);
};
export default Scan;
