import Scan from "./components/Scan";
import Reset from "./components/Reset";
import ScanSetup from "./components/scannarea/ScanSetup";
import "./App.css";
//Redux
import {Provider} from "react-redux";
import {store} from "./context/store";
const App = () => {
	return (
		<Provider store={store}>
			<div className="App">
				<div className="container-fluid wrapper" style={{padding: "0px"}}>
					<div className="row-fluid">
						<div className="span12">
							<nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark fixed-top">
								<div className="container">
									<Scan />
									<Reset />
								</div>
							</nav>
						</div>
						<ScanSetup />
					</div>
				</div>
			</div>
		</Provider>
	);
};

export default App;
