import { State } from "../services/ApiClient";

export interface QRState {
  status: State | null;
  device: MediaDeviceInfo | null;
}

const initialState = {
  status: null,
  device: null,
};

type StatusAction = { type: "SET_STATUS"; payload: State };
type DeviceAction = { type: "SET_DEVICE"; payload: MediaDeviceInfo };
type Action = StatusAction | DeviceAction;

export const qrReducer = (state: QRState = initialState, action: Action) => {
  switch (action.type) {
    case "SET_STATUS": {
      return {
        status: action.payload,
        device: state.device,
      };
    }
    case "SET_DEVICE": {
      return {
        status: state.status,
        device: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
